<template>
  <Page color="success" title="Personen">
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <v-spacer></v-spacer>
        <v-text-field
          clearable
          placeholder="Name, Kürzel, Klasse"
          v-model="search"
          hide-details
          dense
        ></v-text-field>
        <v-btn class="ml-2" outlined text @click="showMine()">Meine</v-btn>
      </v-row>
    </v-container>

    <v-card class="mb-4" v-if="answer">
      <v-system-bar
        >Die Antwort auf die endgültige Frage nach dem Leben, dem Universum und
        dem ganzen Rest.</v-system-bar
      >
      <v-img :src="require('../../assets/answer.png')"></v-img>
    </v-card>
    <v-card v-for="(item, index) in items" :key="index" class="mb-4">
      <v-system-bar>{{ item.description }}</v-system-bar>
      <v-list>
        <PersonItem
          v-for="(person, index) in item.items"
          :key="index"
          :value="person"
        >
          <v-btn
            text
            outlined
            :to="{
              name: 'Person',
              params: { id: person.id },
            }"
            >Profil anzeigen</v-btn
          >
        </PersonItem>
      </v-list>
    </v-card>
  </Page>
</template>

<script>
import { debounce } from "lodash";
import PersonItem from "@/components/PersonItem.vue";

import { sortPeople } from "common/utils/people.js";

export default {
  components: { PersonItem },
  data() {
    return {
      abortController: null,
      loading: false,
      search: "",
      items: [],
      id: 0,
    };
  },
  computed: {
    answer() {
      return this.search && this.search.toLowerCase() === "sinn des lebens";
    },
  },
  watch: {
    id() {
      this.$router.push({ name: "Person", params: { id: this.id } });
    },
    search() {
      this.fetchData();
    },
  },
  created() {
    if (localStorage.getItem("PersonSearch")) {
      this.search = localStorage.getItem("PersonSearch");
    } else {
      this.fetchData();
    }
  },
  methods: {
    fetchData: debounce(async function () {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      this.loading = true;
      localStorage.setItem("PersonSearch", this.search);
      const items = await this.apiList({
        resource: "person/person",
        query: `search=${this.search}`,
        signal: this.abortController.signal,
      });
      items.forEach((item) => (item.items = sortPeople(item.items)));
      this.items = items;
      this.abortController = null;
      this.loading = false;
    }, 300),
    showMine() {
      this.search = "";
    },
  },
};
</script>
